
body {
  background: url("../img/ui/dashboard/bg_subtle_dots.png") repeat scroll 0 0 #fff;
}

// TYPES CHANGES
// -----------
h1, h2, h3, h4, h5, h6 {
  margin-bottom: @line-height-base / 2;
  margin-top: 0;
  font-weight: normal;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 18px;
}

h3 {
  font-size: 20px;
}

h3.app-ico:before {
    display: none;
}

body {
  font-size: 12px;
}

label, input, button, select, textarea {
  font-size: 12px;
}


.breadcrumb {
  margin-top: 20px;
}

.page-header {
  padding: 0 0 10px 0;
  margin: 20px 0 0 0;
  border-bottom: 0;
}



.no-float {
  float: none;
}

.container-fluid.dashboard {
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}

.content-block {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 15px;
  background: @white;
}

.tabbable.dashboard {
  margin-bottom: @line-height-base;
  .navbar, .nav-tabs {
    margin-bottom: 0;
  }
  .tab-content {
    display: block;
    width: auto;
    border: 1px #E1E1E1 solid;
    border-top-width: 0;
    padding: 30px;
  }
}



// WELLS
// -----
.well,
.alert,
.table-bordered {
  .border-radius(0);
}

.well {
  .clearfix();
  box-shadow: none;
  background-color: #efefef;
  border-color: #bbb;
}

.well-success {
  background-color: @state-success-bg;
  border-color: @state-success-border;
}

.well-danger {
  background-color: @state-danger-bg;
  border-color: @state-danger-border;
}

.well-info {
  background-color: @state-info-bg;
  border-color: @state-info-border;
}

.well-blank {
  background-color: @white;
  border-color: rgba(0,0,0,.1);
}

.well-stacked {
  .well {
    margin-bottom:0;
    border-top-width:0;
    &:first-child {
      border-top-width:1px;
    }
  }
}

.create-page .well,
.promotions .well-info {
    background-color: @white;
}


// ALERTS
// -----
.alert-message.block-message.file_upload p {
  margin-right:10px;
}


// MODALS
// -----
.modal {
  > form {
    margin-bottom:0;
  }
}


// SIDE NAV CREATE PAGES
// ---------------------
div[data-spy="affix"] {
  width: 266px;
}

div[data-spy="affix"].affix {
  top: @line-height-base;
}

.bs-docs-sidenav {
  padding: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.065);
     -moz-box-shadow: 0 1px 4px rgba(0,0,0,.065);
          box-shadow: 0 1px 4px rgba(0,0,0,.065);
}
.bs-docs-sidenav > li > a,
.bs-docs-sidenav > li > span {
  display: block;
  margin: 0;
  padding: 8px 14px;
  border: 1px solid #e5e5e5;
  border-top-width: 0;
  color: @link-color;
  &:hover {
   background: #f1faff;
  }
  i.icon-info-sign {
    color: #ea0001;
    font-size: 16px;
  }
}
.bs-docs-sidenav > li > span {
  color: #333;
}
.bs-docs-sidenav > .active > a,
.bs-docs-sidenav > .active > span {
  font-weight: bold;
  position: relative;
  color: @link-color;
  background: #f1faff;
  z-index: 2;
  padding: 9px 15px;
  text-shadow: 0 1px 0 rgba(0,0,0,.15);
  &:hover {
    background: #f1faff;
    color: @link-color;
  }
  &:after {
    border-bottom: 10px solid transparent;
    border-left: 10px solid #f1faff;
    border-top: 10px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: -9px;
    top: 9px;
  }
  &:before {
    border-bottom: 11px solid transparent;
    border-left: 11px solid #ccc;
    border-top: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: -10px;
    top: 8px;
  }
}


// RESPONSIVE
// ---------
@media (max-width: 1300px) {
  div[data-spy="affix"] {
    width: 200px;
  }
  .navbar-primary .nav {
    > li a:before {
      display: none;
    }
  }
  table .btn {
    padding: 3px 9px;
    font-size: @font-size-base - 2px;
    line-height: @line-height-base - 2px;
  }
}
@media (max-width: 979px) {
  .affix {
    position: static;
  }
  div[data-spy="affix"] {
    width: 100%;
    margin-bottom: @line-height-base;
  }
  .navbar-primary .nav > li > a {
    border: 0;
    .border-radius(4px);
  }
  .navbar-primary .nav > li > a:hover {
    background: #222;
  }
}

