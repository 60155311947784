
@font-family-sans-serif:      "Open Sans", 'Helvetica Neue', Helvetica, Arial, sans-serif;

@navbar-padding-vertical:     14px;


//== Oscar variables
@white:                       #fff;
@graph-height:                330px;
@graph-segments-height:       33px;
@bar-height:                  300px;
@bar-width:                   8%;

